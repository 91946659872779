/* import Font Awesome and Google font(s) */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css');

/* import the necessary Bootstrap files */
@import '../node_modules/bootstrap/scss/functions';
/* override Bootstrap variables (before importing Bootstrap!!!) */
@import 'projectVariables';
@import '../node_modules/bootstrap/scss/variables';
/* Optional Sass map overrides here (just before the Bootstrap maps!!) */
@import 'projectMaps';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/utilities';
@import 'projectUtilities';
@import '../node_modules/bootstrap/scss/bootstrap';

/* create custom styles/partials */
@import 'projectLayout';
@import 'student1/student1';
@import 'student2/student2';
@import 'student3/student3';
@import 'student4/student4';
