// basic layout of all pages
* {
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

.z-index-fixed {
  z-index: 1;
}
.img-responsive {
  margin-top: 15px;
  object-fit: contain; /* keep aspect ratio */
  width: 100%;
}
.bg-color {
  background-color: $banner-footer-color;
}

.standard-fontsize {
  font-size: 18px;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.picture-bottom {
  object-position: bottom;
}

@media only screen and (max-width: 992px) {
  .card-img-top {
    width: 100%;
    height: 30vw;
    object-fit: cover;
  }
}

@media only screen and (max-width: 768px) {
  .card-img-top {
    width: 100%;
    height: 45vw;
    object-fit: cover;
  }
}

.img-small {
  width: 100%;
  height: 17vw;
  object-fit: cover;
}

.text-custom {
  margin-top: 3%;
}

.text-color {
  color: $banner-footer-color;
}

.title {
  text-shadow: 2px 2px 2px black;
}

#about-us-picture {
  margin-top: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px, rgba(0, 0, 0, 0.22) 0 15px 12px;}

.navbar-custom {
  top: 20%;
}

.padding-top-heavy {
  padding-top: 100px;
}

.okapi-rood {
  color: $okapi-rood
}

.logo {
  transform: translateY(-40px);
  border-radius: 50%;
  padding: 15px;
}

.index2 {
  header {
    position: relative;
    background-color: black;
    height: 100vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
  }

  header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  header .container {
    position: relative;
    z-index: 2;
  }

  header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }

  /* Media Query for devices withi coarse pointers and no hover functionality */

  /* This will use a fallback image instead of a video for devices that commonly do not support the HTML5 video element */

  @media (pointer: coarse) and (hover: none) {
    header {
      background: url("../assets/heroVideo.png") black no-repeat center center scroll;
      background-size: cover;
    }

    header video {
      display: none;
    }
  }
}

@media only screen and (max-width: 500px) {
  .padding-top-heavy {
    padding-top: 150px;
  }

  .small-screen-fontsize {
    font-size: 12px;
    line-height: 6px;
  }

  .hide-small {
    display: none;
  }

  .larger-images {

  }
}

.hidden {
  opacity: 0;
  transition: all 1s;
  user-select: none;
  pointer-events: none;
}
.show {
  opacity: 1;
}

.hide-item {
  display: none;
}

.show {
  display: block;
}
